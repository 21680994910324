import React from "react";
import { graphql } from "gatsby";
import { Layout } from "antd";
import SEO from "../components/seo";
import { useTranslation } from "react-i18next";
import Header from "../components/PageLayout/Header";
import SidebarWrapper from "../components/PageLayout/Sidebar";

const BlockContent = require("@sanity/block-content-to-react");

const PolyticPage = ({ data }) => {
  const { i18n, t } = useTranslation("common");

  const MenuItems = data.allSanityMenu.nodes;

  const keywords = data.sanityPage.ceo_tags.map(item => item.translate);

  return (
    <Layout className="outerPadding">
      <Layout className="container">
        <SEO
          lang={i18n.language}
          title={data.sanityPage.ceo_title.translate}
          description={data.sanityPage.ceo_description.translate}
          path={data.sanityPage.slug.current}
          keywords={keywords}
        />

        <Header MenuItems={MenuItems} />
        <SidebarWrapper>
          <div className="marginTopTitle">
            <h1>{data.sanityPage.title.translate}</h1>

            <BlockContent blocks={data.sanityPage.body.translate} />
          </div>
        </SidebarWrapper>

      </Layout>
    </Layout>
  );
};

export const query = graphql`
  query Polytic($language: String, $slugName: String) {
    allSanityMenu(filter: { disabled: { in: true } }, sort: { fields: indx }) {
      nodes {
        title {
          translate(language: $language)
        }
        path
        externUrl
      }
    }
    sanityPage(slug: { current: { eq: $slugName } }) {
      title {
        translate(language: $language)
      }
      ceo_description {
        translate(language: $language)
      }
      body {
        translate(language: $language)
      }
      ceo_tags {
        translate(language: $language)
      }
      ceo_title {
        translate(language: $language)
      }
      slug {
        current
      }
    }
  }
`;

export default PolyticPage;
